<template>
  <view-item-setting>
    <flex-box>
      <template #default="{ height }">
        <table-dynamic
          :height="height"
          code="HINTFD"
          :data="table.data"
          :loading="table.loading"
          :paging="table.paging"
          :method="table.get"
          pagination
          :config="table.config"
        >
          <template #MESSAGE="{ row }: { row: EnocloudCommonDefinitions['LookupDto'] }">
            <en-button type="primary" link @click="table.operation.message.click(row)">{{ row.message }}</en-button>
          </template>
        </table-dynamic>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" title="编辑提示信息" direction="btt" size="60%">
    <flex-box>
      <template #default="{ height }">
        <en-table :data="detail.table.data" @current-change="detail.table.currentChange" :height="height" :loading="detail.table.loading">
          <en-table-column label="序号" type="index" width="100"></en-table-column>
          <en-table-column label="提示信息">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['LookupDto'] }">
              <span v-if="['SVOTCRT', 'TAXRT'].includes(row.type.code)">
                <en-input-number controls-position="right" :step="10" :precision="2" v-model="row.number" :max="100" :min="0"></en-input-number>%
              </span>
              <span v-else-if="['MILEAGE', 'MONTH'].includes(row.type.code)">
                <en-input-number :controls="false" :precision="0" v-model="row.name" :min="0"></en-input-number>
                {{ ['MONTH'].includes(row.type.code) ? '月' : '' }}
              </span>
              <en-input v-model="row.name" v-else></en-input>
            </template>
          </en-table-column>
          <en-table-column label="提示信息缩写" prop="hint">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['LookupDto'] }">
              <en-input v-model="row.hint"></en-input>
            </template>
          </en-table-column>

          <en-table-column label="操作" width="200">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['LookupDto'] }">
              <button-delete :method="detail.table.delete.click" text>删除</button-delete>
            </template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>
    <span @click="detail.form.add.click" class="cursor-pointer text-sm text-primary"> 添加提示信息 </span>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click"> 确定 </button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { calculator } from '@enocloud/utils'

const hintInit = (type: any) => ({
  name: '',
  hint: '',
  type
})
interface InspectionPlanItems {
  category: EnocloudCommonDefinitions['LookupDto'] | undefined
}
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.visible = true
          }
        }
      },
      tabs: {
        active: ''
      },
      table: {
        config: {
          MESSAGE: { header: { filter: { type: 'text', field: 'message' } } },
          OPERATION: { visible: false },
        },
        ajax: {
          get: {
            action: 'GET /enocloud/common/lookup/:lookupType',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.paths = ['HINT']
         
            },
          
          }
        },

        children: {
          operation: {
            data: [] as InspectionPlanItems[],
            message: {
              async click(row: EnocloudCommonDefinitions['LookupDto']) {
                this.detail.form.data.type = row
                await this.detail.table.get()
                this.detail.visible = true
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              code: '',
              type: {
                code: ''
              }
            },
            children: {
              add: {
                click() {
                  this.detail.table.data.unshift(hintInit(this.detail.form.data.type))
                }
              }
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/hint/:hintTypeStr',
                data: 'array',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.type.code]
                },
                convert(data) {
                  return data.map((item) => {
                    if (item.type.valueType === 'PERCENTAGE') {
                      item.number = calculator.mul(item.name, 100)
                    }
                    return item
                  })
                }
              },
              submit: {
                action: 'PUT /enocloud/common/hint/:hintTypeStr',
                data: 'array',
                loading: true,
                validate: true,
                params(params) {
                  params.paths = [this.detail.form.data.type.code]
                  params.data = this.detail.table.data
                }
              }
            },

            delete: {
              click(index: number) {
                this.detail.table.data.splice(index, 1)
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.table.submit()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
